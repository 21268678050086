import React from "react"
import "./article.scss"

const Article = ({ module }) => (
  <article
    className="article"
    dangerouslySetInnerHTML={{
      __html: module.text,
    }}
  />
)

export default Article
