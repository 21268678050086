import React from "react"
import { Layout, Header, SEO } from "../"
import Modules from "../modules"

const ModularPage = ({ data }) => {
  const { headerImage, seoMetaTags, modules } = data.datoCmsPage
  return (
    <Layout>
      <SEO tags={seoMetaTags.tags} />
      <Header image={headerImage} />
      <Modules modules={modules} />
    </Layout>
  )
}

export default ModularPage
