import React from "react"
import "./layout.scss"
import Nav from "../nav/nav"

const Layout = ({ children, home }) => {
  return (
    <div className="layout-container">
      <Nav home={home} />
      <main className="main">{children}</main>
    </div>
  )
}

export default Layout
