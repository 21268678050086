import React from "react"
import Helmet from "react-helmet"

const SEO = ({ tags }) => {
  const title = tags.find(t => t.tagName === "title")
  const meta = tags.filter(t => t.tagName === "meta").map(m => m.attributes)
  return (
    <Helmet
      title={title.content}
      titleTemplate={`%s | Filthy Crook`}
      meta={meta}
    ></Helmet>
  )
}

export default SEO
