import React from "react"
import "./icon-links.scss"
import AppleMusic from "../../../images/applemusic.svg"
import Spotify from "../../../images/spotify.svg"
import Deezer from "../../../images/deezer.svg"
import Tidal from "../../../images/tidal.svg"
import X from "../../../images/ax.png"
import Facebook from "../../../images/facebook.svg"
import Insta from "../../../images/instagram.svg"
import Youtube from "../../../images/youtube.svg"
import SoundCloud from "../../../images/soundcloud.svg"

const icons = {
  spotify: Spotify,
  deezer: Deezer,
  applemusic: AppleMusic,
  tidal: Tidal,
  levykauppax: X,
  youtube: Youtube,
  facebook: Facebook,
  instagram: Insta,
  soundcloud: SoundCloud,
}

const IconLinks = ({ module }) => {
  const { iconLinks, title } = module.selectIconGroup
  return (
    <section className="icon-links__container">
      <h1>{title}</h1>
      <div className="icons">
        {iconLinks.map(i => (
          <a key={i.id} target="_blank" rel="noopener noreferrer" href={i.link}>
            <img alt={i.icon} src={icons[i.icon]} />
          </a>
        ))}
      </div>
    </section>
  )
}

export default IconLinks
