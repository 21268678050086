import React, { useState } from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import "./nav.scss"

const Nav = ({ home = "" }) => {
  const [open, setOpen] = useState(false)
  const toggleOpen = () => {
    setOpen(!open)
  }
  const data = useStaticQuery(graphql`
    query NavigationQuery {
      datoCmsNavigation {
        links {
          title
          slug
          id
        }
      }
    }
  `)
  return (
    <nav className={`navigation ${home && "home"}`}>
      <div className={`nav-wrapper ${open ? "open" : ""}`}>
        {data.datoCmsNavigation.links.map(l => (
          <Link
            key={l.id}
            onClick={() => setOpen(false)}
            to={l.slug === "home" ? "/" : `/${l.slug}`}
          >
            {l.title}
          </Link>
        ))}
      </div>
      <div
        onClick={toggleOpen}
        className={`nav-button ${open ? "active" : ""}`}
        role="button"
        aria-hidden="true"
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
    </nav>
  )
}

export default Nav
