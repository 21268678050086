import React from "react"
import "./header.scss"
import Img from "gatsby-image"

const Header = ({ image }) => {
  if (!image) {
    return null
  }
  return (
    <header className="header-image">
      <Img alt={image.alt} fluid={image.fluid} />
    </header>
  )
}

export default Header
