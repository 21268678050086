import React from "react"
import Img from "gatsby-image"
import IconLinks from "../icon-links/icon-links"
import "./image-text.scss"

const ImageText = ({ module }) => {
  const { groups } = module.selectImageTextGroup
  return groups.map(g => (
    <section key={g.id} className="text-image__container">
      {g.title && <h1>{g.title}</h1>} 
      <div className="blocks">
        <aside>
          {g.image && <Img alt={g.image.alt} fluid={g.image.fluid} />}
        </aside>
        <article>
          <div
            dangerouslySetInnerHTML={{
              __html: g.text,
            }}
          />
         {g.iconGroupReference && <IconLinks module={{ selectIconGroup: g.iconGroupReference }} /> } 
        </article>
      </div>
    </section>
  ))
}

export default ImageText
