import React from "react"
import Article from "./article/article"
import ComponentNotFound from "./component-not-found/component-not-found"
import IconLinks from "./icon-links/icon-links"
import ImageText from "./image-text/image-text"
import GigList from "./gig-list/gig-list"

const ComponentList = {
  article: Article,
  icon_group_reference: IconLinks,
  text_image_group_reference: ImageText,
  gig_list: GigList,
}

const GetModule = type => {
  if (typeof ComponentList[type] === "undefined") {
    return ComponentNotFound
  }
  return ComponentList[type]
}

const Modules = ({ modules }) =>
  modules.map(m =>
    React.createElement(GetModule(m.model.apiKey), {
      key: m.id,
      module: m,
    })
  )

export default Modules
