import React from "react"
import { useQuery } from "@apollo/react-hooks"
import gql from "graphql-tag"
import { useStaticQuery, graphql } from "gatsby"
import "./gig-list.scss"
import { format, isAfter } from "date-fns"
import orderBy from "lodash.orderby"

const GIG_QUERY = gql`
  {
    allGigs(orderBy: date_ASC) {
      date
      title
      eventLink
      id
      venueAndCity
    }
  }
`

const List = ({ data, linkTitle }) => {
  return data.map(g => {
    const d = format(new Date(g.date), "dd.MM.yyyy")
    return (
      <div key={g.id} className="gig">
        <header>
          <time>{d}</time>
          {` – `}
          <span className="venue">{g.venueAndCity}</span>
        </header>

        <h2>{g.title}</h2>

        {g.eventLink && (
          <a
            target="_blank"
            key={g.id}
            className="gig"
            rel="noopener noreferrer"
            href={g.eventLink}
          >
            {linkTitle}
          </a>
        )}
      </div>
    )
  })
}

const GigList = () => {
  const { loading, error, data } = useQuery(GIG_QUERY)
  const t = useStaticQuery(graphql`
    query TranslationQuery {
      datoCmsTranslationString {
        gigsPast
        gigsFuture
        gigLinkTitle
      }
    }
  `)
  const { gigsPast, gigsFuture, gigLinkTitle } = t.datoCmsTranslationString

  if (loading || error) return null

  const today = new Date()

  const upComingGigs = data.allGigs.filter(g =>
    isAfter(new Date(g.date), today)
  )

  const pastGigs = data.allGigs.filter(g => isAfter(today, new Date(g.date)))

  return (
    <section className="gig-list__container">
      <article className="article">
        <h1>{gigsFuture}</h1>
        <div className="gigs">
          <List data={upComingGigs} />
        </div>
        <h1>{gigsPast}</h1>
        <div className="gigs">
          <List
            linkTitle={gigLinkTitle}
            data={orderBy(pastGigs, "date", "desc")}
          />
        </div>
      </article>
    </section>
  )
}

export default GigList
